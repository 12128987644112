import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

import "../style/NewsStyles.css";

const News = () => {
  async function fetchNews() {
    const apiKey = "pub_380128f6dae967479c8eae66935f7cb6c3b8f";
    const apiUrl = `https://newsdata.io/api/1/news?apikey=${apiKey}&country=tr`;
    console.log(`API url: ${apiUrl}`);

    try {
      const response = await fetch(apiUrl);
      const data = await response.json();

      return data.results; // Sadece makalelerin listesini alıyoruz
    } catch (error) {
      console.error("Haberleri çekerken bir hata oluştu:", error);
      return [];
    }
  }

  const [news, setNews] = useState([]);

  useEffect(() => {
    async function fetchAndSetNews() {
      const fetchedNews = await fetchNews();
      setNews(fetchedNews.slice(0, 4)); // Sadece en yeni 4 haber
    }

    fetchAndSetNews();
  }, []);

  return (
    <>
      <div id="worksPage">
        <h1 className="worksTitle">Haberler</h1>
        <Row className="g-4 allCards">
          {news.map((article, index) => (
            <Col key={index} md={12} lg={6}>
              <Card className="card">
                <Card.Body>
                  <Card.Title className="tolq">{article.title}</Card.Title>

                  <Button
                    href={article.link}
                    target="_blank"
                    className="moreBut d-block m-auto"
                    variant="outline-success"
                  >
                    Detay
                  </Button>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      </div>
    </>
  );
};

export default News;
