import React from "react";
import Navbars from "../components/Navbars";
import Footer from "../components/Footer";
import News from "../components/News";
import Security from "../components/Security";
import Vote from "../components/Vote";
import Contact from "../components/Contact";

const Main = () => {
  return (
    <div>
      {/* The part where we combine all the components separately */}
      <Navbars />
      <News />
      <Vote />
      <Security />
      <Contact />
      <Footer />
    </div>
  );
};

export default Main;
