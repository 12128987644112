import React from "react";
import chp from "../assets/chp-hazirlik.jpg";
import akp from "../assets/akprti.jpg";
import mhp from "../assets/mhp.jpg";
import saadet from "../assets/saadet.jpg";
import refah from "../assets/yenidenrefah.jpg";
import dem from "../assets/dem.jpg";
import iyi from "../assets/iyi.jpg";
import baskan from "../assets/baskan.png";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import "../style/NavStyles.css";

import { useState } from "react";
import Carousel from "react-bootstrap/Carousel";

const Navbars = () => {
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };

  return (
    <>
      <Navbar expand="lg" className="bg-white nav">
        <Container fluid className="insideNav">
          <Navbar.Brand href="#">
            <img src={baskan} className="logo" alt="logo" />
          </Navbar.Brand>
          <Navbar id="navbarScroll" className="all">
            <Nav className="links">
              <Nav.Link className="startbut" href="#">
                Anasayfa
              </Nav.Link>
              <Nav.Link href="#contactPage">İletişim</Nav.Link>
              <Nav.Link className="workBut" href="#guvenlik">
                Gizlilik & Güvenlik
              </Nav.Link>
              <Nav.Link
                className="startbut"
                id="capital"
                href="http://capitalmedya.com/"
              >
                CapitalMedya
              </Nav.Link>
            </Nav>
          </Navbar>
        </Container>
      </Navbar>
      <Carousel
        className="carousel"
        activeIndex={index}
        onSelect={handleSelect}
      >
        <Carousel.Item>
          <img src={refah} className="carouselImg" alt="logo" />
        </Carousel.Item>
        <Carousel.Item>
          <img src={chp} className="carouselImg" alt="logo" />
        </Carousel.Item>
        <Carousel.Item>
          <img src={mhp} className="carouselImg" alt="logo" />
        </Carousel.Item>
        <Carousel.Item>
          <img src={saadet} className="carouselImg" alt="logo" />
        </Carousel.Item>
        <Carousel.Item>
          <img src={akp} className="carouselImg" alt="logo" />
        </Carousel.Item>
        <Carousel.Item>
          <img src={iyi} className="carouselImg" alt="logo" />
        </Carousel.Item>
        <Carousel.Item>
          <img src={dem} className="carouselImg" alt="logo" />
        </Carousel.Item>
      </Carousel>
    </>
  );
};

export default Navbars;
