import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "../style/FooterStyles.css";
import twit from "../assets/twitter.svg";
import insta from "../assets/instagram.svg";
import baskan from "../assets/baskan.png";

const Footer = () => {
  return (
    <Row className="d-flex flex-col border-t topDiv space-between">
      <Col sm={12} md={6} lg={4} className="flex-col justify-start items-start">
        <img
          src={baskan}
          alt="logo"
          width={118}
          height={18}
          className="footLogo"
        />
        <div className=" justify-between items-center flex-wrap border-t reserveText">
          <p>
            @2024{" "}
            <a
              className="capitalMedya"
              target="blank"
              href="http://capitalmedya.com/"
            >
              CapitalMedya.
            </a>
          </p>
          <p>All Rights Reserved</p>
        </div>
      </Col>

      <Col sm={12} md={6} lg={4} className="contact__link">
        <div>
          <h1 className="footerHead">İletişim</h1>
        </div>
        <div>
          <p className="address">
            Ankara Teknoloji Geliştirme Bölgesi, Üniversiteler Mah. 1606. Cad.
            No:11, CYBERPARK, Bilkent Bulvarı D:H Blok, 06800 Ankara <br />{" "}
            <br /> Türkiye
          </p>
          <a
            href="mailto:capitalmedyatr@gmail.com"
            id="email"
            className="footerText"
          >
            Mail gönder
          </a>
        </div>
      </Col>
      <Col sm={12} md={6} lg={4} className="follow__link">
        <div>
          <h1 className="footerHead">Bizi Takip Et</h1>
        </div>
        <div className="social">
          <a target="blank" href="https://twitter.com/capitalmed72501">
            <img src={twit} alt="twit" />
          </a>
          <a target="blank" href="https://www.instagram.com/capitalmedyatr/">
            <img src={insta} alt="insta" />
          </a>
        </div>
      </Col>
    </Row>
  );
};

export default Footer;
