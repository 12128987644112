import React from "react";
import lamb from "../assets/Untitled_Artwork 2.svg";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "../style/SecurityStyles.css";

const Security = () => {
  return (
    <>
      <Container fluid className="values" id="guvenlik">
        <Row className="d-flex valuesDiv">
          <Col sm={12} md={9}>
            <h2 className="valuesHeadText">Gizlilik & Güvenlik</h2>
          </Col>
          <Col sm={12} md={3}>
            <img src={lamb} alt="lamb" className="d-flex lamb" />
          </Col>
        </Row>
        <Row>
          <Col sm={12} md={6}>
            <div>
              <p className="numberText">01.</p>
              <hr />
              <p className="underText">
                Seçim Anket Sistemi internet sitesine girmeniz ve/veya
                işbuinternet sitesini kullanmanız
                <br />
                aşağıdaki şart ve koşulları kabul ettiğiniz anlamına
                gelmektedir.{" "}
              </p>
            </div>
          </Col>
          <Col sm={12} md={6}>
            <div>
              <p className="numberText">02.</p>
              <hr />
              <p className="underText">
                Seçim Anket Sistemi internet sitesine girmeniz ve/veya işbu
                internet sitesini ve/veya işbu internet sitesindeki bilgileri
                kullanmanızdan kaynaklanan doğrudan ve/veya dolaylı maddi
                ve/veya manevi menfi ve/veya müsbet, velhasıl her türlü zarardan
                her nam altında olursa olsun Seçim Anket Sistemi yönetim kurulu
                üyeleri, yöneticileri, çalışanları ve bu sitede yer alan
                bilgileri hazırlayan kişiler sorumlu tutulamaz.
              </p>
            </div>
          </Col>
          <Col sm={12} md={6}>
            <div>
              <p className="numberText">03.</p>
              <hr />
              <p className="underText">
                İşbu internet sitesinde yer alan, bunları içeren ama bunlarla
                sınırlı olmayan tüm malzeme ve dökümanlar Seçim Anket Sistemi
                mülkiyetinde olup, bu malzeme ve dökümanlara ilişkin telif hakkı
                ve/veya diğer fikri mülkiyet hakları ilgili kanunlarca
                korunmakta olup, bu malzemeler ve dökümanlar izinsiz
                kullanılamaz, iktisap edilemez ve değiştirilemez. İşbu internet
                sitesinde adı geçen başkaca şirketler ve ürünleri sahiplerinin
                ticari markalarıdır ve ayrıca fikri mülkiyet hakları kapsamında
                korunmaktadır.
              </p>
            </div>
          </Col>
          <Col sm={12} md={6}>
            <div>
              <p className="numberText">04.</p>
              <hr />
              <p className="underText">
                Seçim Anket Sistemi hiçbir şekil ve surette ön ihbara ve/veya
                ihtara gerek kalmaksızın her zaman
                <br />
                işbu internet sitesinde yer alan her türlü bilgiyi
                değiştirebilir, düzeltebilir ve/veya çıkarabilir.{" "}
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Security;
