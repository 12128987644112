import React from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import "../style/ContactStyles.css";
import rocket from "../assets/Subtract.svg";

const Contact = () => {
  function validateForm(e) {
    e.preventDefault();

    const email = document.querySelector(".inputEmail").value;
    const message = document.querySelector(".messageInput").value;
    const errorMessage = document.getElementById("errorMessage");

    if (email === "" || message === "") {
      errorMessage.innerHTML =
        "Email ve Mesaj doldurulması zorunlu alanlardır.";
    } else {
      // Form is valid, you can submit it or perform additional actions
      document.getElementById("myForm").submit();
    }
  }

  return (
    <div id="contactPage" className="body">
      <div className="formAllDiv">
        <div className="formTextDiv">
          <h1 className="formHead">
            Bizimle <br /> iletişime geçmek ister misiniz?
          </h1>
          <p className="formText">Düşüncelerinizi aşağıda paylaşabilirsiniz.</p>
        </div>
        <div className="cardAndRocket d-flex flex-wrap">
          <div>
            <Form
              id="myForm"
              className="formCard"
              method="POST"
              action="https://getform.io/f/c244c5d8-f16a-4be3-9c81-cb16e3e5d96c"
            >
              <Form.Group className="inputGroup">
                <Form.Control
                  className="input"
                  type="text"
                  placeholder="İsim*"
                  name="isim"
                />
              </Form.Group>
              <Form.Group className="inputGroup">
                <Form.Control
                  className="inputEmail"
                  name="e-mail"
                  type="email"
                  placeholder="Email*"
                />
              </Form.Group>
              <Form.Group className="inputGroup">
                <Form.Control
                  className="messageInput"
                  name="message"
                  as="textarea"
                  rows={3}
                  placeholder="Mesaj*"
                />
              </Form.Group>
              <Button
                onClick={validateForm}
                className="messageBut"
                variant="outline-success"
              >
                Mesaj Gönder
              </Button>
              <div id="errorMessage" style={{ color: "red" }}></div>
            </Form>
          </div>

          <div className="d-flex rocketDiv">
            <img className="rocket" src={rocket} alt="rocket" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
