import React, { useEffect } from "react";
import CloudMan from "../assets/Untitled_Artwork 6.png";
import BinocularsMan from "../assets/Untitled_Artwork 5.png";
import "../style/VoteStyles.css";

const Vote = () => {
  useEffect(() => {
    const script = document.createElement("script");
    script.type = "text/javascript";
    script.async = true;
    script.innerHTML = `
    (function(t,e,s,n){var o,a,c;t.SMCX=t.SMCX||[],e.getElementById(n)||(o=e.getElementsByTagName(s),a=o[o.length-1],c=e.createElement(s),c.type="text/javascript",c.async=!0,c.id=n,c.src="https://widget.surveymonkey.com/collect/website/js/tRaiETqnLgj758hTBazgd4mFCCRqAQo_2FtNRVDVMl4mYSLyYNIzNo4Cvup0qV8r5g.js",a.parentNode.insertBefore(c,a))})(window,document,"script","smcx-sdk");
    `;

    const surveyContainer = document.getElementById("surveyContainer");

    // Script'i eklemek
    if (surveyContainer) {
      surveyContainer.appendChild(script);
    }

    return () => {
      // Temizleme işlemleri
      if (surveyContainer) {
        surveyContainer.removeChild(script);
      }
    };
  }, []);

  return (
    <div id="hiringPage" className="d-flex hiringDiv flex-wrap">
      <div>
        <img src={CloudMan} alt="CloudMan" className="CloudMan" />
      </div>
      <div>
        <h1 className="hiringHead">Anket</h1>
        <div id="surveyContainer"></div>
        <div id="my-div">
          <iframe
            src="https://tr.surveymonkey.com/results/SM-Hj8wyGELD9syT_2B_2BYhCqUXQ_3D_3D/"
            height="500"
            width="700"
            title="secim"
            id="my-iframe"
            scrolling="no"
          ></iframe>
        </div>
      </div>
      <div>
        <img
          src={BinocularsMan}
          alt="BinocularsMan"
          className="BinocularsMan"
        />
      </div>
    </div>
  );
};

export default Vote;
